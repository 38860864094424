import React from 'react'

const HouseIcon = (props)=>(
  <svg xmlns="http://www.w3.org/2000/svg" width="29.2" height="21.2" viewBox="0 0 29.2 21.2">
    <g id="Group_3353" data-name="Group 3353" transform="translate(-446.4 -191.179)">
      <g id="Group_2355" data-name="Group 2355" transform="translate(447 191.778)">
        <path id="Path_15478" data-name="Path 15478" d="M-11622.516-14048.194l-15.615-2.425,6.844-2.687Z" transform="translate(11638.146 14055.165)" fill="#fff" stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.2"/>
        <path id="Path_15479" data-name="Path 15479" d="M-11594.989-14060.222l6.493,3.593-12.268,3.38-8.551-5.115Z" transform="translate(11616.181 14060.222)" fill="#fff" stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.2"/>
        <path id="Path_15480" data-name="Path 15480" d="M-11572.458-14030.548l12.37-7.888-.3-8.5-12.071,3.352Z" transform="translate(11588.088 14050.548)" fill="#fff" stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.2"/>
        <path id="Path_15481" data-name="Path 15481" d="M-11638.156-14033.126l15.606,5.094v-13.029l-15.62-2.407Z" transform="translate(11638.17 14048.032)" fill="#fff" stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.2"/>
      </g>
      <path id="Path_15487" data-name="Path 15487" d="M-11625.8-14029.526l7.7.921-.258,9.051-7.75-2.56Z" transform="translate(12075.971 14229.645)" fill="#fff" stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.2"/>
    </g>
  </svg>
)

export default HouseIcon