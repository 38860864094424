/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon16 from "../images/favicon.ico";
import favicon32 from "../images/favicon.ico";
import favicon64 from "../images/favicon.ico";

function SEO({ description, lang, meta, title, schemaMarkup }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const commonSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "ProBuilt Steel Buildings",
    "image": "https://www.probuiltsteel.com/static/logo-9b82a20b2d5c6f5abcf45d07f8ac5bc9.svg",
    "@id": "",
    "url": "https://www.probuiltsteel.com/",
    "telephone": "+1-877-754-1818",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "3752 W US Hwy 90, Lake City, FL 32055",
      "addressLocality": "Lake City",
      "addressRegion": "FL",
      "postalCode": "32055",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 30.1790506,
      "longitude": -82.6939257
    },
    "sameAs": [
      "https://www.facebook.com/ProBuiltSteelBuildings/",
      "https://www.instagram.com/probuiltsteelbuildings/",
      "https://www.youtube.com/channel/UCgX_B_Nfel_Z9Tbo1JLSCCA",
      "https://maps.app.goo.gl/6TdYp7r8gomizV4y8"
    ]
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        { rel: "icon", type: "image/ico", sizes: "16x16", href: `${favicon16}` },
        { rel: "icon", type: "image/ico", sizes: "32x32", href: `${favicon32}` },
        { rel: "icon", type: "image/ico", href: `${favicon64}` },
      ]}
    >
      {schemaMarkup ? (
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      ):(
        <script type="application/ld+json">{JSON.stringify(commonSchema)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
