import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./Header"
import Footer from "./Footer"
// import ExitIntentPopup from "./ExitIntentPopup"
import "./layout.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LayoutWrap = styled.div`
  position: relative;
`
const ScrollToTopBtn = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: black;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 999;

  @media (max-width: 767.9px) {
    bottom: 4.5rem;
    right: 1rem;
  }
`

const Layout = ({ children, location, isShowProduct, hideSearch = false }) => {
  // const [isExitIntentVisible, setExitIntentVisible] = useState(false)
  // const [leaveTryNumber, setLeaveTryNumber] = useState(2);
  const [isVisible, setIsVisible] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  // const handleMouseEvent = () => {
  //   setLeaveTryNumber(leaveTryNumber + 1)
  // }

  // const handleExitModalClose = () => {
	// 	setExitIntentVisible(false)
  //   setLeaveTryNumber(2)
	// }

  // useEffect(()=> {
  //   if(exitIntentPopup) {
  //     setLeaveTryNumber(0)
  //   }
  // }, [])

  // useEffect(() => {
  //   if(leaveTryNumber === 1) {
  //     setExitIntentVisible(true)
  //   }
  // }, [handleMouseEvent])

  useEffect(() => {
    if(isShowProduct){
      document.body.classList.add("crm-product");
    } else {
      document.body.classList.remove("crm-product");
    }
  }, [])

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [])  

  return (
    <LayoutWrap>
      <Header siteTitle={data.site.siteMetadata.title} hideSearch={hideSearch} />
      <main>{children}</main>
      <Footer location={location} />
      {/* <ExitIntentPopup isVisible={isExitIntentVisible} onClose={handleExitModalClose}  /> */}
      {isVisible && (
        <ScrollToTopBtn onClick={scrollToTop}> ↑ </ScrollToTopBtn>
      )}
    </LayoutWrap>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout