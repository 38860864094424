import { useStaticQuery, graphql } from "gatsby"
import { useEffect, useState } from "react"

export const useSearch = (keyword) => {
  const [results, setResults] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        edges {
          node {
            id
            skuNumber
            image {
              fluid(maxWidth: 700, quality: 100) {
                base64
                sizes
                aspectRatio
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            imageSrc
            title
            width
            length
            height
            price
            priceDescription
            productServices {
              spaceId
              service
              image {
                fluid(maxWidth: 45, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcWebp
                  srcSetWebp
                  srcSet
                }
              }
            }
            productRoofType {
              name
            }
            productCategory {
              name
            }
            numberOfSale
            canBeUsedAs {
              content
            }
          }
        }
      }
    }
  `)
  const { allContentfulProduct } = data;

  const checkWithKeyword = (product, search) => {
    // if (product.id.toLowerCase().includes(search.toLowerCase())) return true;
    if (product.title.toLowerCase().includes(search.toLowerCase())) return true;
    // if (product.productCategory.name.toLowerCase().includes(search.toLowerCase())) return true;
    // if (product.productRoofType.name.toLowerCase().includes(search.toLowerCase())) return true;
    // if (product.skuNumber.toString().toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  }

  useEffect(() => {
    if (!keyword) {
      setResults([]);
    } else {
      const rs = (allContentfulProduct.edges || []).map(p => p.node).filter(p => checkWithKeyword(p, keyword));
      setResults(rs)
    }
  }, [keyword])

  return results
}