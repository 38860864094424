exports.getProductUrl = (productData) => {
  const { title, productCategory, productRoofType, id } = productData

  const categoryUrl = productCategory.name
    .split(" ")
    .join("-")
    .toLowerCase()
  const roofTypeUrl = productRoofType.name
    .split(" ")
    .join("-")
    .toLowerCase()
  const temp = title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
  const titleUrl = temp
    .split(" ")
    .join("-")
    .toLowerCase();

  if (categoryUrl === "carolina-barns" || categoryUrl === "seneca-barns") {
    return `/barns/${categoryUrl}/${titleUrl}`;
  } else if (categoryUrl === "commercial-buildings") {
    return `/commercial-metal-buildings/${titleUrl}`;
  } else if (categoryUrl === "storage-buildings" || categoryUrl === "metal-warehouses") {
    return `/${categoryUrl}/${titleUrl}`;
  } else {
    return `/${categoryUrl}/${roofTypeUrl}/${titleUrl}`;
  }
}