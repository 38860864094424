import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const SecondaryBtn = styled(BaseButton)`	
	color:#fff;
	border:#fff;
    &:before {
        border-top-color:#FF7245;
    }
    
    &:after {
        border-bottom-color:#FF3E00;
    }
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	
	&:hover{
		color:#fff;	
	  	background:#FF7245;	    
	}

	@media(max-width: 480px) {
		&:hover,
		&:focus,
		&:active {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
				transform: none;
			}
			&:after { 
				transform: none;
			}
		}
	}
`
const SecondaryButton = (props) => {
	const { icon, iconAfter, text, size } = props;
	return(
		<SecondaryBtn className={`btn ${size ? `btn-${size}` : ''}`}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</SecondaryBtn>
	)
}


export default SecondaryButton
