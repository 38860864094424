import React, { Component } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class AboutProbuiltMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>About Probuilt</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/service-area/">Service Area Map </Link>
          <Link to="/contact-us/">Contact Us </Link>
          <Link to="/reviews/">Reviews </Link>
          <Link to="/faqs/">FAQ’s </Link>
          <Link to="/blog/">Blog </Link>
          <Link to="/infographics/">Infographic </Link>
          <Link to="/case-studies/">Case Studies </Link>
          <RedLink to="/about/">About Probuilt </RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default AboutProbuiltMobileNav
